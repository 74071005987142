import { FormControl, TextField } from "@mui/material";
import React, { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import driveTraffic from "../../../images/driveMoreTraffic.png";
import TrafficGraph from "../../../images/TrafficGraph.png";

const NotifyFunnels = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(newEmail)) {
      setEmailError(false);
    }
  };
  return (
    <div className="notifyFunnels_container">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12 notifyFunnels_details">
            <h4>Discover Our Exclusive AssignmentSold Tools and Services</h4>
            <p>
              Streamline your assignment sale journey with our specialized
              tools. From personalized listing alerts to accurate market
              insights, our services are designed to help you make informed
              decisions and maximize opportunities in the real estate market.
            </p>
            <ul>
              <li>&nbsp;&nbsp;Daily Listing Alerts</li>
              <li>&nbsp;&nbsp;Dream Home Finder</li>
              <li>&nbsp;&nbsp;Accurate Home Valuation</li>
              <li>&nbsp;&nbsp;Instant “Just Listed” Updates</li>
              <li>&nbsp;&nbsp;Dream Property Notification</li>
              <li>&nbsp;&nbsp;Deals and Discounst</li>
              <li>&nbsp;&nbsp;Seller Alerts</li>
            </ul>

            <div className="w-100 d-flex flex-wrap mt-3 gap-2">
              <FormControl
                style={{ maxWidth: "380px", width: "calc(100% - 115px)" }}
              >
                <TextField
                  sx={{
                    borderRadius: "4px",
                  }}
                  size="small"
                  className="w-100 bg-light"
                  value={email}
                  onChange={handleEmailChange}
                  name="email"
                  id="email"
                  label={<span className="font">Enter your Email</span>}
                  variant="outlined"
                />
                {emailError ? (
                  <span style={{ color: "red" }}>Enter valid email</span>
                ) : null}
              </FormControl>
              <button
                className="propertyDetails_moreInfo py-2 d-flex align-items-center justify-content-center notifyFunnels_btn"
                // onClick={addNotify}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ThreeCircles
                    visible={true}
                    height="30"
                    width="30"
                    color="#fff"
                    ariaLabel="three-circles-loading"
                  />
                ) : (
                  `Notify Me`
                )}
              </button>
            </div>
          </div>

          <div className="col-md-6 col-12 notifyFunnels_graph h-100">
            <div className="d-flex align-items-center justify-content-between w-100 h1-00">
              <div className="marketing_graph h-100">
              <img src={TrafficGraph} alt="Traffic Graph" lazy="loading" className="w-100 h-100"  />
        
              </div>
              <div className="marketing_customer">
                <h5>999+</h5>
                <p>
                  Over 1,500 successful assignment sales completed, helping
                  clients achieve their real estate goals effortlessly.
                </p>
                <div className="progress-bar">
                  <div className="progress-filled"></div>
                </div>
              </div>
            </div>
            <div className="trafficImg">
              <img src={driveTraffic} alt="drive Traffic" lazy="loading" className="w-100 h-100"  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotifyFunnels;
