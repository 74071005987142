import React from "react";
import OurMissionImg from "../../../images/OurMission.png";
import { PlayMusicIcon } from "../../../SvgIcons/Icons";


const OurMission = () => {
  return (
    <div className="container">
      <div className="ourMission_container">
        <div className="row">
          <div className="col-md-7 col-12">
            <div className="ourMission_Img position-relative">
              <img
                src={OurMissionImg}
                alt="Our Mission"
                loading="lazy"
                className="w-100 h-100"
              />
              <div className="position-absolute ourMission_play">
                <PlayMusicIcon/>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-12">
            <h5>Our Mission</h5>
            <p>
              An assignment sale isn’t a direct real estate sale; instead, it’s
              the transfer of a contract to purchase a property before its
              completion. Here’s how it works:
            </p>
            <ul>
              <li className="mt-3">
                “An <strong>assignment sale </strong> transfers a contract, not
                the property itself, ensuring flexibility for buyers and
                sellers.”
              </li>
              <li className="mt-3">
                <strong>Assignee:</strong> The buyer transfers this purchase
                agreement to another party before the property is ready for
                occupancy.
              </li>
              <li className="mt-3">
                <strong>Benefits:</strong> The assignee gains ownership upon
                project completion without the hassle of starting from scratch.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
