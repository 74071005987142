import ClosingAgreement from "../../../images/ClosingAgreement.png";
import AssignmentAgreement from '../../../images/AssignmentAgreement.png'
import QualifiedBuyer from '../../../images/QualifiedBuyer.png'
import BusinessNotification from '../../../images/BusinessNotification.png'
import AssignmentGuide from '../../../images/AssignmentGuide.png'
import LandEscapeCard from "./SubComponent/LandEscapeCard";
import PotraitCard from "./SubComponent/PotraitCard";

const AssignHomeSteps = () => {
  const data = {
    card1: {
      step: "1",
      heading: "Thorough Contract Terms Assessment Guide 📜✨",
      subHeading: "Understand every detail for confident decision-making.",
      imgUrl: AssignmentGuide,
      imgAlt: "Assignment Guide",
      desccription:
        "Carefully examine the terms of your original agreement to determine eligibility for assigning your property, ensuring compliance with contractual obligations and legal requirements. 📋✅🏡",
    },
    card2: {
      step: "2",
      heading: "Builder Notification Services 🏗️📩",
      subHeading: "Seamless communication for smooth project progress.",
      imgUrl: BusinessNotification,
      imgAlt: "Business Notification",
      desccription:
        "Notify the builder or developer of your intent to assign the property, ensuring all required conditions and contractual obligations are met for a smooth transition. 🏗️📩✅",
    },
    card3: {
      step: "3",
      heading: "Match with a Qualified Buyer 🔑🤝",
      subHeading: "Connecting you with the perfect buyer effortlessly.",
      imgUrl: QualifiedBuyer,
      imgAlt: "Qualified Buyer",
      desccription:
        "Promote your property to attract potential buyers by leveraging a professional realtor or trusted listing platforms to connect with qualified prospects effectively. 📣🏡🤝",
    },
    card4: {
      step: "4",
      heading: "Assignment Agreement Drafting Services 📝🔏",
      subHeading: "Customized agreements tailored to your needs.",
      imgUrl: AssignmentAgreement,
      imgAlt: "Assignment Agreement",
      desccription:
        "Collaborate with a lawyer or real estate agent to draft a comprehensive and legally binding assignment agreement, ensuring all parties' interests are protected. 📝⚖️🤝",
    },
    card5: {
      step: "5",
      heading: "Secure the Closing Agreement 🔐🤝",
      subHeading: "Ensuring every deal ends successfully.",
      imgUrl: ClosingAgreement,
      imgAlt: "Closing Agreement",
      desccription:
        "Finalize the assignment by completing all legal and financial processes. Transfer the contract and ensure all parties meet their obligations for a seamless transaction. ⚖️💰📜",
    },
  };

  return (
    <div className="assignHome_contianer py-5">
      <div className="container">
        <div className="d-flex flex-column align-items-center g-2 justify-content-center">
          <h3>
            How to Assign a Home in <br /> 5 Simple Steps
          </h3>
          <p className="subtitle">
            Assigning a home is straightforward when you follow these simple
            steps. Use this guide to understand the process and get started
          </p>
        </div>

        <div className="row mt-3">
          <div className="col-md-6 col-12 assignHome_PortCard">
            <PotraitCard data={data?.card1} />
          </div>
          <div className="col-md-6 col-12 d-flex flex-column gap-4">
           <div className="h-50">
           <LandEscapeCard data={data?.card2} />
           </div>
            <div className="h-50">
              <LandEscapeCard data={data?.card3} />
            </div>
          </div>
          <div className="col-md-6 col-12 mt-4">
            <LandEscapeCard data={data?.card4} />
          </div>
          <div className="col-md-6 col-12 mt-4">
            <LandEscapeCard data={data?.card5} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignHomeSteps;
