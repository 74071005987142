import React from "react";

const LandEscapeCard = ({ data }) => {
  return (
    <div className="assignHome_landCard">
      <div className="assignHome_landCardImg">
        <img
          src={data?.imgUrl}
          alt={data?.imgAlt}
          loading="lazy"
          className="w-100 h-100"
        />
      </div>

      <div className="assignHome_landCardContent">
        <div className="badge">{data?.step}</div>
        <h6>{data?.subHeading}</h6>
        <h5>{data?.heading}</h5>
        <p>{data?.desccription}</p>
      </div>
    </div>
  );
};

export default LandEscapeCard;
