import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Box, Modal } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import baseURL from "../../../config";
import {
  CloseIcon,
  SuccesfullySubmitedIcon,
  ComingSoonHomeIcon,
} from "../../../SvgIcons/Icons";

const FunnelModal = ({
  data,
  isUserlogin,
  setIsFunnelModalOpen,
  isFunnelModalOpen,
}) => {
  const jwt_token = isUserlogin;
  const [isLoading, setIsLoading] = useState(false);

  const WatchNeighBourData = data?.listingUserActivityDTO?.watchNeighbour;
  const [isNewListing, setIsNewListing] = useState(false);
  const [isSoldListing, setIsSoldListing] = useState(false);
  const [isDelistedListing, setIsDelistedListing] = useState(false);
  const [selectedFunnels, setSelectedFunnels] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isSuccessfullySubmited, setIsSuccessfullySubmited] = useState(true);

  const funnels = [
    "Daily Listing Alerts",
    "Accurate Home Valuation",
    "Dream Property Notification",
    "Seller Alerts",
    "Dream Home Finder",
    "Instant “Just Listed” Updates",
    "Deals and Discount",
  ];

  useEffect(() => {
    const isNew = localStorage.getItem("isNewListing") === "true";
    const isSold = localStorage.getItem("isSoldListing") === "true";
    const isDelisted = localStorage.getItem("isDelistedListing") === "true";
    setIsNewListing(isNew);
    setIsSoldListing(isSold);
    setIsDelistedListing(isDelisted);
  }, []);

  const handleClose = () => {
    setIsFunnelModalOpen(false);
  };

  const handleCheckboxChange = (funnel) => {
    if (selectedFunnels.includes(funnel)) {
        setSelectedFunnels(selectedFunnels.filter((fn) => fn !== funnel));
      } else {
        setSelectedFunnels([...selectedFunnels, funnel]);
      }
  };

  const handleSubmit = async () => {
    let error = false;
    if (
      !isFunnelModalOpen &&
      !isNewListing &&
      !isDelistedListing &&
      !isSoldListing
    ) {
      error = true;
    }

    if (!error && isUserlogin) {
      setIsLoading(true);
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const Watchdata = {
        Id: WatchNeighBourData ? WatchNeighBourData?.id : "0",
        ProId: data?.uId,
        Lat: data?.latitude,
        Lng: data?.longitude,
        NewListing: isNewListing ? isNewListing : false,
        SoldListing: isSoldListing ? isSoldListing : false,
        DeListed: isDelistedListing ? isDelistedListing : false,
      };
      try {
        const response = await axios.post(
          `${baseURL}/api/AuthData/AddWatchNeighbour`,
          Watchdata,
          {
            headers,
          }
        );
        if (response?.data?.result === 1) {
          setIsSuccessfullySubmited(true);
          setIsLoading(false);
          localStorage.setItem("isNewListing", isNewListing);
          localStorage.setItem("isSoldListing", isSoldListing);
          localStorage.setItem("isDelistedListing", isDelistedListing);
          setIsFunnelModalOpen(
            isNewListing || isDelistedListing || isSoldListing ? true : false
          );
        } else {
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      open={isFunnelModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="Prop_detail_infoModal WatchNeighbourHood_inputs funnels_modals d-flex flex-column align-items-center justify-content-center"
      >
        {isSuccessfullySubmited ? (
          <div className="d-flex flex-column align-items-center">
            <div className="propertyDetail_homeValue_icon">
              <SuccesfullySubmitedIcon />
            </div>
            <h6 style={{ fontSize: "20px" }} className="text-center mt-3">
              Successfully Updated
            </h6>
            <p style={{ fontSize: "16px" }} className="mt-1 text-center">
              {isSoldListing || isDelistedListing || isNewListing
                ? "Thank you! You will receive an email with updates based on your selected Funnels."
                : "You have unselected all Funnels. You will no longer receive updates for these Funnels. To receive updates, please select at least one option."}
            </p>

            <div className="mt-2 w-100">
              <button onClick={handleClose}>Okay</button>
            </div>
          </div>
        ) : (
          <>
            <div className="propertyDetail_homeValue_icon">
              <ComingSoonHomeIcon />
            </div>
            <h6 style={{ fontSize: "25px" }} className="mt-2">
              Select Your Preferred Tool
            </h6>
            <p style={{ fontSize: "16px" }} className="mt-2 text-center">
              Let us know which tools you're interested in to serve you better.
            </p>
            {funnels?.map((funnel, index) => (
              <div className="w-100 d-flex align-items-center mt-3" key={index}>
                <input
                  type="checkbox"
                  checked={selectedFunnels?.includes(funnel)}
                  onChange={() => handleCheckboxChange(funnel)}
                />
                <span className="ps-2">{funnel}</span>
              </div>
            ))}

            {isSubmited && selectedFunnels?.length === 0 && (
              <div className="mt-3">
                <span style={{ color: "red", marginTop: "10px" }}>
                  Please select at least one listing option to receive
                  updates.
                </span>
              </div>
            )}

            {isLoading ? (
              <div className="aligncenter justifycenter mt-3">
                <RotatingLines width={50} strokeColor="grey" />
              </div>
            ) : (
              <div className="mt-3 w-100">
                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                    setIsSubmited(true);
                  }}
                >
                  Submit
                </button>
              </div>
            )}
          </>
        )}
        <div
          className="position-absolute Property_detailsWeb_hide"
          style={{ top: "20px", right: "15px" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </div>
      </Box>
    </Modal>
  );
};

export default FunnelModal;
