import React, { useEffect, useState } from "react";
import HeaderSection from "../../HomePage/SubComponents/HeaderSection/HeaderSection";
import { ToastContainer } from "react-toastify";

const SearchPage = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <div className={`${isMobileView ? "" : "p-3 mt-5"}`}>
      <HeaderSection isSearchPage={true} />
    </div>

    <ToastContainer />
    </>
  );
};

export default SearchPage;
